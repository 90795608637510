<template>
  <div>
    <div class="e-breadcrumb">商品管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">商品名称:</span>
              <el-input placeholder="商品名称" size="small" style="width:265px" v-model="skuName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">品牌:</span>
              <el-select size="small" clearable v-model="brandId" style="width: 265px;" placeholder="请选择品牌">
                <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id">
                </el-option>
              </el-select>
              <span class="v-form-label">供货商:</span>
              <el-select size="small" clearable v-model="supplierId" style="width: 265px;" placeholder="请选择供货商">
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :label="item.manufactorName"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div style="margin-right: 37px">
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="80" class="th-l">商品图片</th>
                <th width="130" class="th-l">商品编号</th>
                <th width="130" class="th-l">商品名称</th>
                <th width="130" class="th-l">分类</th>
                <th width="130" class="th-l">品牌</th>
                <th width="130" class="th-l">供货商</th>
                <th width="130" class="th-l">零售价</th>
                <th width="130" class="th-l">最低价</th>
                <th width="250" class="th-l">会员价</th>
                <th width="80" class="th-l">是否卖品</th>
                <th width="160" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><img style="width: 60px;height: 60px;" :src="row.skuImgUrl"/></td>
                <td class="td-l"><span>{{row.skuNo}}</span></td>
                <td class="td-l"><span>{{row.skuName}}</span></td>
                <td class="td-l">{{row.categoryName}}</td>
                <td class="td-l">{{row.brandName}}</td>
                <td class="td-l">{{row.manufactorName}}</td>
                <td class="td-l">{{row.retailPrice}}</td>
                <td class="td-l">{{row.minimumPrice}}</td>
                <td class="td-l">
                  <div v-if="row.skuCardPrices.length>0" v-for="item in row.skuCardPrices">
                    {{item.cardName+' '+item.price+' 元'}}
                    <span @click="toUpdateIC(index,item)" style="cursor:pointer;color: #1b80ff">
                [修改]
            </span>
                    <span @click="toDelIC(item)" style="cursor:pointer;color: #1b80ff">
                [删除]
            </span>
                  </div>
                  <p @click="toHYDJ(index)" style="cursor:pointer;color: #1b80ff">设置</p>
                </td>
                <td class="td-l">
                  <span v-if="row.isSale==0">卖品</span>
                  <span v-if="row.isSale==1">非卖品</span>
                </td>
                <td class="td-l"><span>{{row.createTime}}</span></td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="会员定价" :visible.sync="hydjVisible" width="20%">
      <div style="width: 100%">
        <el-form ref="form" label-width="35%">
          <el-form-item size="small" label="商品名称:" required>
            <span>{{item.skuName}}</span>
          </el-form-item>
          <el-form-item size="small" label="会员卡类型:" required>
            <el-select v-model="cardId" placeholder="请选择" style="width: 60%">
              <el-option
                v-for="item in cardList"
                :key="item.id"
                :label="item.cardName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="small" label="会员价:" required>
            <el-input style="width: 60%" placeholder="价格" v-model="price">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="hydjVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveSkuPrice">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="delIcVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delIcVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delSkuCard">确 定</el-button>
            </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addShpSkuSuccess"/>
    <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit" @editSuccess="editShpSkuSuccess"
          :editItem="editItem"/>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delShpSku">确 定</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
  import Insert from './insert'
  import Edit from './edit'
  //import { getShpSkuList, deleteShpSku} from 'api/shpSku'

  export default {
    data() {
      return {
        skuNo: '',
        skuName: '',
        supplierId: '',
        brandId: '',

        supplierList: [],
        brandList: [],
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        editVisible: false,
        delVisible: false,
        editItem: {},

        options: [{
          value: '1',
          label: '是'
        }, {
          value: '0',
          label: '否'
        }],

        item:{},
        skuCard:{},
        skuCardId:'',
        cardId:'',
        price:'',
        cardList:[],
        hydjVisible:false,
        delIcVisible:false
      }
    },
     created() {
      this.searchEvent();
      this.getBrandList();
      this.getSupplierList();
      this.getCardList();
    },
    methods: {

      async getCardList() {
        let res = await this.$get("/admin/getCardList")
        if (res.code == 200) {
          this.cardList = res.cardList
        } else {
          this.$message.error(res.message)
        }
      },

      async saveSkuPrice() {
        let data = {
          id: this.skuCardId,
          skuId: this.item.id,
          cardId: this.cardId,
          price: this.price,
        };
        let res = await this.$post("/admin/saveSkuPrice", data)
        if (res.code == 200) {
          this.searchEvent()
          this.price = ''
          this.cardId = ''
          this.skuCardId = ''
          this.hydjVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },

      //删除
      async delSkuCard() {
        let data = {
          id: this.skuCard.id
        };
        let res = await this.$post("/admin/remove_skuCardPrice", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delIcVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      toUpdateIC(index, item) {
        this.item = this.list[index];
        this.price = item.price
        this.cardId = item.cardId
        this.skuCardId = item.id
        this.hydjVisible = true
      },
      toHYDJ(index) {
        this.item = this.list[index];
        this.hydjVisible = true
      },
      toDelIC(item) {
        this.skuCard = item
        this.delIcVisible = true
      },


      async getSupplierList() {
        let res = await this.$get("/admin/getSupplierList")
        if (res.code == 200) {
          this.supplierList = res.supplierList
        }
      },
      async getBrandList() {
        let res = await this.$get("/admin/getBrandList")
        if (res.code == 200) {
          this.brandList = res.brandList
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },

      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          skuName: this.skuName,
          supplierId: this.supplierId,
          brandId: this.brandId,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getShpSkuForPage", data)
        this.list = res.dataSet.list;
        console.log(this.list)
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pages: res.dataSet.pages
        }
      },

      addEvent() {
        this.$router.push('/skus/saveSku')
      },

      editEvent(index) {
        this.$router.push('/skus/updateSku?id='+this.list[index].id)
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除ShpSku
      async delShpSku() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/remove_shpSku", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

      closeInsert() {
        this.addVisible = false;
      },

      closeEdit() {
        this.editVisible = false;
      },

      addShpSkuSuccess() {
        this.closeInsert();
        this.searchEvent();
      },

      editShpSkuSuccess() {
        this.closeEdit();
        this.searchEvent();
      }
    },
    components: {
      Insert,
      Edit
    }
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
