<template>
  <div>
    <div class="e-breadcrumb"><span style="margin-left: -10px" @click="closeEvent">商品管理</span> > 商品编辑</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div>
          <el-form ref="00" label-width="150px" style="padding: 30px">
            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="商品编号:" required>
                  <el-input v-model="item.skuNo" placeholder="请输入商品编号" maxlength="32"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="商品名称:" required>
                  <el-input v-model="item.skuName" placeholder="请输入商品名称" maxlength="255"
                            show-word-limit></el-input>
                </el-form-item>

              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="商品条码:" required>
                  <el-input v-model="item.skuCode" placeholder="请输入商品条码" maxlength="32"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="商品规格:" required>
                  <el-input v-model="item.skuSpecs" placeholder="请输入商品规格" maxlength="255"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="商品单位:" required>
                  <el-input v-model="item.unitValue" style="width: 49%;" placeholder="请输入商品数量"></el-input>
                  <el-select v-model="item.unitType+''" style="width: 49%;margin-left: 10px" placeholder="请选择单位">
                    <el-option
                      v-for="item in unitTypeList"
                      :key="item.dictValue"
                      :label="item.name"
                      :value="item.dictValue">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="商品大类" required>
                  <el-select  v-model="item.categoryId" style="width: 100%;"
                             placeholder="请选择大类">
                    <el-option
                      v-for="item in categoryList"
                      :key="item.id"
                      :label="item.categoryName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>


            <el-row>

              <el-col :span="12">
                <el-form-item size="small" label="品牌:" required>
                  <el-select v-model="item.brandId" style="width: 100%;" placeholder="请选择品牌">
                    <el-option
                      v-for="item in brandList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="供货商:" required>
                  <el-select v-model="item.supplierId" style="width: 100%;" placeholder="请选择供货商">
                    <el-option
                      v-for="item in supplierList"
                      :key="item.id"
                      :label="item.manufactorName"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="零售价:" required>
                  <el-input v-model="item.retailPrice" placeholder="请输入零售价" maxlength="12"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="最低价:" required>
                  <el-input v-model="item.minimumPrice" placeholder="请输入最低价" maxlength="12"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="初始库存:" required>
                  <el-input v-model="item.initInventory" placeholder="请输入初始库存" maxlength="12"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="安全库存量:" required>
                  <el-input v-model="item.safetyStock" placeholder="请输入安全库存量" maxlength="12"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="是否卖品:" required>
                  <el-select v-model="item.isSale" style="width: 100%;" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="备注:" required>
                  <el-input type="textarea" rows="4" v-model="item.remarks"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item size="small" label="商品图片:" required>
                  <div class="graphic-description-value">
                    <div>
                      <el-upload
                        class="e-file-upload"
                        :action="Base_url+'/admin/uploadImg'"
                        :on-success="imageSuccess"
                        :before-upload="beforeUpload"
                        :show-file-list="false"
                      >
                        <el-image v-if="skuImgUrl"
                                  style="width: 135px; height: 136px"
                                  :src="skuImgUrl"
                                  fit="contain"></el-image>
                        <div class="e-upload--text" v-if="!skuImgUrl">
                          <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                          <p>添加上传图片</p>
                        </div>
                        <p class="e-file-upload-delete" v-if="skuImgUrl"
                           @click.stop="skuImgUrl=''">
                          <i class="el-icon-delete"></i>
                        </p>
                      </el-upload>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="margin-bottom: 30px">
                <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="saveShpSku">确 定</el-button>
            </span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        skuImgUrl:'',
        skuId: '',
        unitTypeList: [],
        categoryList: [],
        brandList: [],
        supplierList: [],
        options: [
          {
            value: 0,
            name: '卖品',
          }, {
            value: 1,
            name: '非卖品',
          }
        ],

        show: false,
        item: {},
      }
    },
    created() {
      this.skuId = this.$route.query.id,
        this.Base_url = Base_url
      this.getSku()
      this.getUnitTypeList()
      this.getCategoryList()
      this.getBrandList()
      this.getSupplierList()
    },
    methods: {
      async getSku() {
        let res = await this.$get("/admin/getSku", {id: this.skuId})
        if (res.code == 200) {
          this.item = res.shpSku
          this.skuImgUrl=res.shpSku.skuImgUrl
          this.parentId = res.shpSku.parentId
        }
      },

      async getCategoryList() {
        let data = {
          categoryType: 1
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },
      async getSupplierList() {
        let res = await this.$get("/admin/getSupplierList")
        if (res.code == 200) {
          this.supplierList = res.supplierList
        }
      },
      async getBrandList() {
        let res = await this.$get("/admin/getBrandList")
        if (res.code == 200) {
          this.brandList = res.brandList
        }
      },
      async getUnitTypeList() {
        let res = await this.$get('/admin/getDictDetailList/UNIT_TYPE/' + Math.random());
        this.unitTypeList = res;
      },
      //图片相关
      imageSuccess(res) {
        console.log(res)
        this.skuImgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },


      closeEvent() {
        this.$router.push('/skus/sku')
      },
      async saveShpSku() {
        if (!this.item.skuNo) {
          this.$message.error('请输入商品编号');
          return
        }
        if (!this.item.skuName) {
          this.$message.error('请输入商品名称');
          return
        }
        if (!this.item.skuCode) {
          this.$message.error('请输入商品条码');
          return
        }
        if (!this.item.skuImgUrl) {
          this.$message.error('请上传商品图片');
          return
        }
        if (!this.item.skuSpecs) {
          this.$message.error('请输入商品规格');
          return
        }
        if (!this.item.unitType.toString()) {
          this.$message.error('请选择单位类型');
          return
        }
        if (!this.item.unitValue) {
          this.$message.error('请输入单位值');
          return
        }
        if (!this.item.categoryId) {
          this.$message.error('请选择分类');
          return
        }
        if (this.item.isSale == null) {
          this.$message.error('请选择是否卖品');
          return
        }
        if (this.item.retailPrice == null) {
          this.$message.error('请输入零售价');
          return
        }
        if (this.item.minimumPrice == null) {
          this.$message.error('请输入最低价');
          return
        }
        if (this.item.initInventory == null) {
          this.$message.error('请输入初始库存');
          return
        }
        if (this.item.safetyStock == null) {
          this.$message.error('请输入安全库存量');
          return
        }
        if (!this.item.brandId) {
          this.$message.error('请选择品牌');
          return
        }
        if (!this.item.supplierId) {
          this.$message.error('请选择供货商');
          return
        }

        let data = {
          id: this.item.id,
          skuNo: this.item.skuNo,
          skuName: this.item.skuName,
          skuCode: this.item.skuCode,
          skuImgUrl: this.skuImgUrl,
          skuSpecs: this.item.skuSpecs,
          unitType: this.item.unitType,
          unitValue: this.item.unitValue,
          categoryId: this.item.categoryId,
          isSale: this.item.isSale,
          retailPrice: this.item.retailPrice,
          minimumPrice: this.item.minimumPrice,
          initInventory: this.item.initInventory,
          safetyStock: this.item.safetyStock,
          brandId: this.item.brandId,
          supplierId: this.item.supplierId,
          remarks: this.item.remarks,
        };

        let res = await this.$post("/admin/save_shpSku", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/skus/sku')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
