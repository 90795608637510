<template>
  <div>
    <div class="e-breadcrumb"><span style="margin-left: -10px" @click="closeEvent">商品管理</span> > 商品新增</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div >
            <el-form ref="form" label-width="150px" style="padding: 30px">
              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="商品编号:" required>
                    <el-input v-model="skuNo"  placeholder="请输入商品编号" maxlength="32"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item size="small" label="商品名称:" required>
                    <el-input v-model="skuName" placeholder="请输入商品名称" maxlength="255"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>

              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="商品条码:" required>
                    <el-input v-model="skuCode"  placeholder="请输入商品条码" maxlength="32"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="small" label="商品规格:" required>
                    <el-input v-model="skuSpecs"  placeholder="请输入商品规格" maxlength="255"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="商品大类" required>
                    <el-select  v-model="categoryId" style="width: 100%;" placeholder="请选择大类">
                      <el-option
                        v-for="item in categoryList"
                        :key="item.id"
                        :label="item.categoryName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="small" label="供货商:" required>
                    <el-select v-model="supplierId" style="width: 100%;" placeholder="请选择供货商">
                      <el-option
                        v-for="item in supplierList"
                        :key="item.id"
                        :label="item.manufactorName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="商品单位:" required>
                    <el-input v-model="unitValue" style="width: 49%;" placeholder="请输入商品数量"></el-input>
                    <el-select v-model="unitType" style="width: 49%;margin-left: 10px" placeholder="请选择单位">
                      <el-option
                        v-for="item in unitTypeList"
                        :key="item.dictValue"
                        :label="item.name"
                        :value="item.dictValue">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item size="small" label="品牌:" required>
                    <el-select v-model="brandId" style="width: 100%;" placeholder="请选择品牌">
                      <el-option
                        v-for="item in brandList"
                        :key="item.id"
                        :label="item.brandName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="零售价:" required>
                    <el-input v-model="retailPrice"  placeholder="请输入零售价" maxlength="12"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="small" label="最低价:" required>
                    <el-input v-model="minimumPrice"  placeholder="请输入最低价" maxlength="12"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>


              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="初始库存:" required>
                    <el-input v-model="initInventory"  placeholder="请输入初始库存" maxlength="12"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="small" label="安全库存量:" required>
                    <el-input v-model="safetyStock"  placeholder="请输入安全库存量" maxlength="12"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="是否卖品:" required>
                    <el-select v-model="isSale" style="width: 100%;" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item size="small" label="备注:" required>
                    <el-input type="textarea" rows="4" style="width: 410px;" v-model="remarks"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item size="small" label="商品图片:" required>
                    <div class="graphic-description-value">
                      <div>
                        <el-upload
                          class="e-file-upload"
                          :action="Base_url+'/admin/uploadImg'"
                          :on-success="imageSuccess"
                          :before-upload="beforeUpload"
                          :show-file-list="false"
                        >
                          <el-image v-if="skuImgUrl"
                                    style="width: 135px; height: 136px"
                                    :src="skuImgUrl"
                                    fit="contain"></el-image>
                          <div class="e-upload--text" v-if="!skuImgUrl">
                            <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                            <p>添加上传图片</p>
                          </div>
                          <p class="e-file-upload-delete" v-if="skuImgUrl"
                             @click.stop="skuImgUrl=''">
                            <i class="el-icon-delete"></i>
                          </p>
                        </el-upload>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>

              </el-row>
              <div style="margin-bottom: 30px;margin-top: 30px">
                <el-button size="small" @click="closeEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="saveShpSku">确 定</el-button>
              </div>
            </el-form>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        skuNo: '',
        skuName: '',
        skuCode: '',
        skuImgUrl: '',
        skuSpecs: '',
        unitType: '',
        unitValue: '',
        categoryId: '',
        isSale: '',
        retailPrice: '',
        minimumPrice: '',
        initInventory: '',
        safetyStock: '',
        brandId: '',
        supplierId: '',
        remarks: '',

        parentId: '',

        unitTypeList: [],
        categoryList: [],
        brandList: [],
        supplierList: [],
        options: [
          {
            value: '0',
            name: '卖品',
          }, {
            value: '1',
            name: '非卖品',
          }
        ],
      }
    },
    props: {
      addVisible: Boolean
    },
    created() {
      this.Base_url = Base_url
      this.getUnitTypeList()
      this.getCategoryList()
      this.getBrandList()
      this.getSupplierList()
    },
    methods: {

      async getCategoryList() {
        let data = {
          categoryType: 1
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },
      async getSupplierList() {
        let res = await this.$get("/admin/getSupplierList")
        if (res.code == 200) {
          this.supplierList = res.supplierList
        }
      },
      async getBrandList() {
        let res = await this.$get("/admin/getBrandList")
        if (res.code == 200) {
          this.brandList = res.brandList
        }
      },
      async getUnitTypeList() {
        let res = await this.$get('/admin/getDictDetailList/UNIT_TYPE/' + Math.random());
        this.unitTypeList = res;
      },

      closeEvent() {
        this.$router.push('/skus/sku')
      },

      //图片相关
      imageSuccess(res) {
        this.skuImgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },

      async saveShpSku() {
        if (!this.skuNo) {
          this.$message.error('请输入商品编号');
          return
        }
        if (!this.skuName) {
          this.$message.error('请输入商品名称');
          return
        }
        if (!this.skuCode) {
          this.$message.error('请输入商品条码');
          return
        }
        if (!this.skuImgUrl) {
          this.$message.error('请上传商品图片');
          return
        }
        if (!this.skuSpecs) {
          this.$message.error('请输入商品规格');
          return
        }
        if (!this.unitType) {
          this.$message.error('请选择单位类型');
          return
        }
        if (!this.unitValue) {
          this.$message.error('请输入单位值');
          return
        }
        if (!this.categoryId) {
          this.$message.error('请选择分类');
          return
        }
        if (!this.isSale) {
          this.$message.error('请选择是否卖品');
          return
        }
        if (!this.retailPrice) {
          this.$message.error('请输入零售价');
          return
        }
        if (!this.minimumPrice) {
          this.$message.error('请输入最低价');
          return
        }
        if (!this.initInventory) {
          this.$message.error('请输入初始库存');
          return
        }
        if (!this.safetyStock) {
          this.$message.error('请输入安全库存量');
          return
        }
        if (!this.brandId) {
          this.$message.error('请选择品牌');
          return
        }
        if (!this.supplierId) {
          this.$message.error('请选择供货商');
          return
        }
        if (!this.remarks) {
          this.$message.error('请输入备注');
          return
        }
        let data = {
          skuNo: this.skuNo,
          skuName: this.skuName,
          skuCode: this.skuCode,
          skuImgUrl: this.skuImgUrl,
          skuSpecs: this.skuSpecs,
          unitType: this.unitType,
          unitValue: this.unitValue,
          categoryId: this.categoryId,
          isSale: this.isSale,
          retailPrice: this.retailPrice,
          minimumPrice: this.minimumPrice,
          initInventory: this.initInventory,
          safetyStock: this.safetyStock,
          brandId: this.brandId,
          supplierId: this.supplierId,
          remarks: this.remarks,
        };

        let res = await this.$post("/admin/save_shpSku", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/skus/sku')
        }else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
